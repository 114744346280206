import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { FormattedMessage } from "react-intl";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <div className="aboutMeText">
            <p>
              <FormattedMessage
                id="about.textCard1"
                defaultMessage=" Hello everyone, I'm Marcos Gabriel Lorenzana from Buenos Aires, Argentina."
                values={
                  {
                    inglés: <b className="green">Marcos Gabriel Lorenzana</b>,
                    español: <b className="green">Marcos Gabriel Lorenzana</b>,
                  }
                }
              />
            </p>
            <br />
            <p>
              <FormattedMessage
                id="about.textCard2"
                defaultMessage=" I acknowledge that I am an organized and responsible person who can work seamlessly both in group settings and individually, and who is always open to personal and professional improvement. Due to these traits and other academic achievements, I was honored as the 'Hope of the Nation 2022' in my final year of high school at Instituto San José Obrero."
                values={
                  {
                    inglés1: <b className="green">organized</b>,
                    inglés2: <b className="green">responsible</b>,
                    inglés3: <b className="green">"Hope of the Homeland 2022"</b>,
                    español1: <b className="green">organizada</b>,
                    español2: <b className="green">responsable</b>,
                    español3: <b className="green">"Esperanza de la Patria 2022"</b>
                  }
                }
              />
            </p>
            <br />
            <p>
              <FormattedMessage
                id="about.textCard3"
                defaultMessage=" Speaking of my high school experience, during the last two years, I participated in three different ventures undertaken as practical assignments evaluated by my Accounting and Administration teachers. In these ventures, I held positions of supervisor and manager in the accounting and administrative sector due to my personal interests and because my peers trusted me to fulfill those roles. However, I also had a strong presence in the production and marketing sectors, as being ventures, we were obliged to share responsibilities among all. These experiences allowed me to enhance my accounting registration skills through Excel (in regards to budgeting, inventories, journals, ledgers, and balances), delve into the use of editing and design programs for advertising purposes such as Canva and Photoshop, learn to remain calm in high-stress situations, and know how to attend to clientele."
              />
            </p>
            <br />
            <p>
              <FormattedMessage
                id="about.textCard4"
                defaultMessage=" Furthermore, since late 2021, I started self-learning various programming languages like HTML, CSS, JavaScript, React, and Firebase. Initially driven by curiosity and the desire to try creating a website, but upon recognizing the potential of these tools, I decided to venture into creating my own website, GabyXer, and embarked on different projects like my password generator, this portfolio to speak more about myself, and many others that I continue to develop or have reserved for the future - mainly implementing React. Additionally, initiating the creation of a website led me to want to make it public to offer my projects to the community, which prompted me to start using even more editing programs, this time including videos, such as Wondershare Filmora 12 and Da Vinci Resolve. Similarly, to be able to program, I used Visual Studio Code, Git, GitHub, and - especially since early 2023 - OpenAI's ChatGPT, which has been greatly helpful in accelerating the lengthy and arduous process that my initial decision entailed."
                values={
                  {
                    inglés1: <b className="green">self-learning</b>,
                    inglés2: <a href="https://gabyxer.com.ar/"><b className="green">GabyXer</b></a>,
                    inglés3: <a href="https://passgenxer.gabyxer.com.ar/"><b className="green">password generator</b></a>,
                    español1: <b className="green">autónoma</b>,
                    español2: <a href="https://gabyxer.com.ar/"><b className="green">GabyXer</b></a>,
                    español3: <a href="https://passgenxer.gabyxer.com.ar/"><b className="green">generador de contraseñas</b></a>
                  }
                }
              />
            </p>
            <br />
            <p>
              <FormattedMessage
                id="about.textCard5"
                defaultMessage=" Besides, I am not complacent with my knowledge, and in 2023, I also started learning about finance and ERPs in a didactic manner. Regarding Enterprise Resource Planning (ERP) software, I focused on SAP and Tango Gestión for now, but I am open to learning even more if necessary."
                values={
                  {
                    inglés1: <b className="green">finance</b>,
                    inglés2: <b className="green">ERPs</b>,
                    español1: <b className="green">finanzas</b>,
                    español2: <b className="green">ERPs</b>,
                  }
                }
              />
            </p>
            <br />
            <p>
              <FormattedMessage
                id="about.textCard6"
                defaultMessage=" It is worth mentioning that when I talk about self-learning or didactic learning, I mean that I have watched multiple free courses on YouTube, visited various websites, and used ChatGPT. I am interested in taking official courses that validate my knowledge in the long term, but so far, I have only been able to access free courses on the internet that have provided me with certificates like 'You count on you', 'Personal economics', and 'Personal finance' from Junior Achievement Argentina or 'Advanced Excel' from Académica (a course created by academic staff from the National Agrarian University). Although I can at least confirm that I have held a driver's license since May 16, 2023."
                values={
                  {
                    inglés1: <b>"You count on you"</b>,
                    inglés2: <b>"Personal economics"</b>,
                    inglés3: <b>"Personal finance"</b>, 
                    inglés4: <a href="https://junior.org.ar/"><b className="green">Junior Achievement Argentina</b></a>,
                    inglés5: <b>"Advanced Excel"</b>,
                    inglés6: <a href="https://academica.mx/index.php?"><b className="green">Académica</b></a>,
                    español1: <b>"Cuentas contigo"</b>,
                    español2: <b>"Economía personal"</b>,
                    español3: <b>"Finanzas personales"</b>,
                    español4: <a href="https://junior.org.ar/"><b className="green">Junior Achievement Argentina</b></a>,
                    español5: <b>"Excel avanzado"</b>,
                    español6: <a href="https://academica.mx/index.php?"><b className="green">Académica</b></a>
                  }
                }
              />
            </p>
            <br />
            <p>
              <FormattedMessage
                id="about.textCard7"
                defaultMessage=" Currently, I am a second-year student at the National University of La Matanza (UNLaM) pursuing a Bachelor's degree in Public Accounting, with 7 subjects passed, and I am currently unemployed, seeking employment."
                values={
                  {
                    inglés1: <b className="green">student</b>,
                    inglés2: <b className="green">Public Accounting</b>,
                    español1: <b className="green">estudiante</b>,
                    español2: <b className="green">Carrera de Grado de Contador Público</b>
                  }
                }
              />
            </p>
            <br />
            <p>
              <FormattedMessage
                id="about.textCard8"
                defaultMessage=" In conclusion, I aim to continue educating myself to adapt to new technologies and use that knowledge to work, undertake, invest, and share with the world all that I know in the future."
              />
            </p>
            <br />
            <br />
            <p>
              <FormattedMessage
                id="about.textCard9"
                defaultMessage=" Here are other activities I like to do in my free time!"
              />
            </p>
          </div>
          <ul>
            <li className="about-activity">
              <ImPointRight /> 
              <text className="about-activity-text">
                <FormattedMessage
                  id="about.textCardActivity1"
                  defaultMessage="Watch series and movies"
                />
              </text>
            </li>
            <li className="about-activity">
              <ImPointRight /> 
              <text className="about-activity-text">
                <FormattedMessage
                  id="about.textCardActivity2"
                  defaultMessage="Playing video games"
                />
              </text>
            </li>
            <li className="about-activity">
              <ImPointRight /> 
              <text className="about-activity-text">
                <FormattedMessage
                  id="about.textCardActivity3"
                  defaultMessage="Read manga and about finance and personal development"
                />
              </text>
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
