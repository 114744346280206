import React, { useState, useEffect, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, getUserInfo, userExists } from "../../firebase/firebase";
import { langContext } from "../../contexto/langContext";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const resumeLinkEs = "https://raw.githubusercontent.com/Gabitox218/resume/master/CVMarcosGLorenzanaES.pdf";
const resumeLinkEn = "https://raw.githubusercontent.com/Gabitox218/resume/master/CVMarcosGLorenzanaEN.pdf";

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const { establecerLenguaje } = useContext(langContext);
  const idioma = localStorage.getItem('lang');
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `Resume | PortfolioMGL`;
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, callBackAuthState);
  }, []);

  async function callBackAuthState(user) {
    if (user) {
      const uid = user.uid;
      console.log(user);
      if (userExists(user.uid)) {
        const loggedUser = await getUserInfo(uid);
        setCurrentUser(loggedUser);
        if (loggedUser.displayName === "") {
          console.log("Regístrate.");
          navigate("/");
        } else {
          console.log("Ya estás registrado.");
          console.log(loggedUser);
        }
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          {idioma === 'es-AR' ? 
            <Button
              variant="primary"
              href="https://raw.githubusercontent.com/Gabitox218/resume/master/CVMarcosGLorenzanaES.pdf"
              target="_blank"
              style={{ maxWidth: "250px" }}
            >
              <AiOutlineDownload />
              &nbsp;
              <FormattedMessage
                id="resume.buttonDownload"
                defaultMessage="Download CV"
              />
            </Button>
            :
            <Button
              variant="primary"
              href="https://raw.githubusercontent.com/Gabitox218/resume/master/CVMarcosGLorenzanaEN.pdf"
              target="_blank"
              style={{ maxWidth: "250px" }}
            >
              <AiOutlineDownload />
              &nbsp;
              <FormattedMessage
                id="resume.buttonDownload"
                defaultMessage="Download CV"
              />
            </Button>
          }
        </Row>

        <Row className="resume">
          {idioma === 'es-AR' ? 
            <Document file={resumeLinkEs} className="d-flex justify-content-center">
              <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} className="Page" />
            </Document> :
            <Document file={resumeLinkEn} className="d-flex justify-content-center">
            <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} className="Page" />
            </Document>
          }
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
