import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthProvider from "./authProvider";
import { Spinner } from "../../funciones/Spinner";

import { logout } from "../../firebase/firebase";

export default function CerrarSesion() {
  useEffect(() => {}, []);
  const navigate = useNavigate();

  return (
    <>
      <AuthProvider
        onUserLoggedIn={async () => {
          await logout();
          navigate("/");
          window.location.reload();
        }}
        onUserNotLoggedIn={() => {
          navigate("/");
          window.location.reload();
        }}
      ></AuthProvider>
      <Spinner/>
    </>
  );
}
