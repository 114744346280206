import React, { useContext, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link, useNavigate } from "react-router-dom";
import {  AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import es from '../imagenes/argentina.png';
import en from '../imagenes/united-states.png';
import {FormattedMessage} from 'react-intl';
import {langContext} from '../contexto/langContext';
import Select from 'react-select';

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const idioma = useContext(langContext);
  const navigate = useNavigate()

  const options = [
    {
      value: 'setting',
      label: (
        <FormattedMessage
          id="navbar.userSetting"
          defaultMessage="Setting"
        />
      ),
      link: '/setting'
    },
    {
      value: 'sign-off',
      label: (
        <FormattedMessage
          id="navbar.userSignOff"
          defaultMessage="Sign Off"
        />
      ),
      link: '/sign-off'
    }
  ];
  

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/home" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> 
                <p className="navbarFormattedMessage">
                  <FormattedMessage 
                    id="navbar.home"
                    defaultMessage="Home"
                  />
                </p>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> 
                <p className="navbarFormattedMessage">
                  <FormattedMessage 
                    id="navbar.about"
                    defaultMessage="About"
                  />
                </p>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> 
                <p className="navbarFormattedMessage">
                  <FormattedMessage 
                    id="navbar.resume"
                    defaultMessage="Resume"
                  />
                </p>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link 
              as={Link}
              onClick={() => updateExpanded(false)}
              style={{ paddingRight: 0 }}
              >
                <FaUserEdit style={{ marginBottom: "2px" }} />
                <p className="navbarFormattedMessage">
                  <FormattedMessage
                    id="navbar.user"
                    defaultMessage="User"
                  />
                </p>
              </Nav.Link>
              <div className="contenedorSelect">
                <Select
                  options={options}
                  onChange={(selectedOption) => {
                    if (selectedOption?.link) {
                      navigate(selectedOption.link);
                    }
                  }}
                  components={{
                    IndicatorSeparator: null // Para ocultar la flecha hacia abajo
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      boxShadow: 'none',
                      cursor: 'pointer',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-around',
                      backgroundColor: 'none',
                      minHeight: 0,
                      top: '5px',
                      left: '60px',
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      display: 'none'
                    }),
                    indicatorContainer: (provided) => ({
                      ...provided,
                      padding: '0px !important'
                    }),
                    menu: (provided) => ({
                      ...provided,
                      marginTop: 0,    
                      position: 'relative'
                    }),
                    option: (provided) => ({
                      ...provided,
                      cursor: 'pointer'
                    })            
                  }}
                />
              </div>
            </Nav.Item>
              
            <Nav.Item>
              <div className="banderas">
					      <button onClick={() => idioma.establecerLenguaje('es-AR')}><img src={es} alt="Español"/></button>
					      <button onClick={() => idioma.establecerLenguaje('en-US')}><img src={en} alt="Inglés"/></button>
				      </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
