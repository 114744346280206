import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { AiFillInstagram, AiOutlineFacebook, AiOutlineMail, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import portadaPersonal from "../../imagenes/PortadaPersonal.png";
import { FaTelegram } from "react-icons/fa";
import {FormattedMessage} from 'react-intl';

function Home2() {
  const correoElectronico = "marcos.lorenzana@gabyxer.com.ar";

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <FormattedMessage
                id="home.presentationSubtitle"
                defaultMessage="LET ME INTRODUCE MYSELF"
                values={
                  {
                    inglés: <b className="green">INTRODUCE</b>,
                    español: <b className="green">PRESENTARME</b>
                  }
                }
              />
            </h1>
            <p className="home-about-body">
              <FormattedMessage
                id="home.presentationText1"
                defaultMessage="Accounting attracted me since I learned about it, in my fourth year of high school - full 2020, where the health threat of the global pandemic by COVID-19 occurred -. Certainly since then I have learned a great deal more and I intend to keep it that way for a long time to come."
                values={
                  {
                    inglés: <b className="green">Accounting</b>,
                    español: <b className="green">contabilidad</b>
                  }
                }
              />
              <br />
              <br />
              <FormattedMessage
                id="home.presentationText2"
                defaultMessage="I have a strong foundation for drafting commercial documents, budgets, and balance sheets, as well as for using Microsoft Office Suite (Excel, Word, PowerPoint, Outlook) and other programs related to collaboration, document management, and project administration. Additionally, I possess knowledge of finance, programming, and editing (images, videos, among others) which I consistently strengthen."
                values={
                  {
                    inglés1: <b className="green">commercial documents</b>,
                    inglés2: <b className="green">budgets</b>,
                    inglés3: <b className="green">balance sheets</b>,
                    inglés4: <b className="green">Microsoft Office Suite</b>,
                    inglés5: <b className="green">collaboration</b>,
                    inglés6: <b className="green">document management</b>,
                    inglés7: <b className="green">project administration</b>,
                    inglés8: <b className="green">finance</b>,
                    inglés9: <b className="green">programming</b>,
                    inglés10: <b className="green">editing</b>,
                    español1: <b className="green">documentos comerciales</b>,
                    español2: <b className="green">presupuestos</b>,
                    español3: <b className="green">balances</b>,
                    español4: <b className="green">Microsoft Office Suit</b>,
                    español5: <b className="green">colaboración</b>,
                    español6: <b className="green">gestión de documentos</b>,
                    español7: <b className="green">administración de proyectos</b>,
                    español8: <b className="green">finanzas</b>,
                    español9: <b className="green">programación</b>,
                    español10: <b className="green">edición</b>,
                  }
                }
              />
              <br />
              <br />
              <FormattedMessage
                id="home.presentationText3"
                defaultMessage="My field of interest is the accounting record adaptable to today's new technologies."
                values={
                  {
                    inglés: <b className="green">accounting record</b>,
                    español: <b className="green">registración contable</b>
                  }
                }
              />
              <br />
              <br />
              <FormattedMessage
                id="home.presentationText4"
                defaultMessage="Whenever I can, I find myself learning new things that will be useful to me in the future."
              />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={portadaPersonal} className="img-fluid" alt="avatar" width="217px" height="237px" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>
              <FormattedMessage
                id="home.presentationPublicityText"
                defaultMessage="Feel free to connect with me"
                values={
                  {
                    inglés: <b className="green">connect</b>,
                    español: <b className="green">contactarse</b>
                  }
                }
              />
            </h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href={`mailto:${correoElectronico}`}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="http://www.linkedin.com/in/marcos-gabriel-lorenzana-k2i5n1g8"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/Gabitox218"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTelegram/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/marcosglorenzana?igshid=ZDdkNTZiNTM="
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/marcos.g.lorenzana"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Lorenzana_MG"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
