import React, {useEffect, useState} from "react";
import "./Usuario.css";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, getUserInfo, registerNewUser, userExists } from "../../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../funciones/Spinner";
import RegistroUsuario from "./RegistroUsuario";
import { FormattedMessage } from "react-intl";
import { serverTimestamp } from "firebase/firestore";

export default function Usuario() {
  const [currentUser, setCurrentUser] = useState(null);
  const [state, setState] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `User | PortfolioMGL`;
  }, []);

  useEffect(() => {
    setState(1);
    onAuthStateChanged(auth, callBackAuthState);
  }, []);

  async function callBackAuthState(user) {
    if (user) {
      const uid = user.uid;
      console.log(user);
  
      if (await userExists(user.uid)) {
        const loggedUser = await getUserInfo(uid);
        setCurrentUser(loggedUser);
        if (!loggedUser.hasOwnProperty('processCompleted') || !loggedUser.processCompleted) {
          console.log("Te falta un nombre de usuario.");
          navigate("/home");
        } else {
          console.log("Ya tiene un nombre de usuario.");
          navigate("/home");
          setState(2);
        } 
      } else {
        console.log("Registrese.");
  
        registerNewUser({
          uid: user.uid,
          displayName: user.displayName,
          processCompleted: true,
          createdAt: serverTimestamp(),
          updates: [],
        });
        navigate("/home");
      }
    } else {
      setState(4);
    }
  }
  
  
  

  function handleAuthGoogle() {
    const googleProvider = new GoogleAuthProvider();
    const signInWithGoogle = async () => {
      try {
        const res = await signInWithPopup(auth, googleProvider);
        if (res) {
          registerNewUser(res.user);
        }
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    };
    signInWithGoogle();
  }
  
  if (state === 1) {
    return <Spinner />;
  }

  if (state === 4) {
    return (
      <div className="loginView">
        <div className="contenedorLogins">
          <RegistroUsuario/>
          <div className="contenedorBotonesInicio">
            <button onClick={() => handleAuthGoogle()} className="providerGoogle">
              <FormattedMessage
                id="user.login2"
                defaultMessage="Login with Google"
              />
            </button>
          </div>  
        </div>
      </div>
    );
  }

  return <Spinner />;
}