import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DiHtml5, DiCss3, DiJavascript1, DiReact } from "react-icons/di";
import { SiFirebase } from "react-icons/si";
import { FormattedMessage } from "react-intl";

function Techstack() {
  const [colState, setColState] = useState({
    html: false, css: false, js: false, react: false, firebase: false,
  });

  const [lastClicked, setLastClicked] = useState(null);

  const handleClick = (colName) => {
    setColState((prevState) => ({
      ...prevState,
      [colName]: !prevState[colName],
    }));

    if (lastClicked !== null && lastClicked !== colName) {
      setColState((prevState) => ({
        ...prevState,
        [lastClicked]: false,
      }));
    }

    setLastClicked(colName);
  };

  const renderCol = (colName, icon, level) => {
    return (
      <Col
        xs={4}
        md={2}
        className={`tech-icons ${colState[colName] ? "rotated" : ""}`}
        onClick={() => handleClick(colName)}
      >
        <div className="icon-container">
          <div className="icon-front">{icon}</div>
          <div className="icon-back">
            <h3>{colName.toUpperCase()}</h3>
            <p>
              {level}
            </p>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {renderCol("HTML", <DiHtml5 />, <FormattedMessage id="about.techIconsLevel2" defaultMessage="Intermediate level"/> )}
      {renderCol("CSS", <DiCss3 />, <FormattedMessage id="about.techIconsLevel2" defaultMessage="Intermediate level"/> )}
      {renderCol("JavaScript", <DiJavascript1 />, <FormattedMessage id="about.techIconsLevel1" defaultMessage="Basic level"/> )}
      {renderCol("React", <DiReact />, <FormattedMessage id="about.techIconsLevel1" defaultMessage="Basic level"/> )}
      {renderCol("Firebase", <SiFirebase />, <FormattedMessage id="about.techIconsLevel1" defaultMessage="Basic level"/> )}
    </Row>
  );
}

export default Techstack;