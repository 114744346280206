import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";
import portadaSobreMi from "../../imagenes/PortadaSobreMi.png";
import { FormattedMessage } from "react-intl";
import { LanguageProgressBar, languages } from "./Languages";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, getUserInfo, userExists } from "../../firebase/firebase";

function About() {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `About | PortfolioMGL`;
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, callBackAuthState);
  }, []);

  async function callBackAuthState(user) {
    if (user) {
      const uid = user.uid;
      console.log(user);
      if (userExists(user.uid)) {
        const loggedUser = await getUserInfo(uid);
        setCurrentUser(loggedUser);
        if (loggedUser.displayName === "") {
          console.log("Regístrate.");
          navigate("/");
        } else {
          console.log("Ya estás registrado.");
          console.log(loggedUser);
        }
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
    setIsLoading(false);
  }

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
              width: "100%"
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <FormattedMessage
                id="about.title"
                defaultMessage="Know who I'm"
                values={
                  {
                    inglés: <b className="green">I'm</b>,
                    español: <b className="green">soy</b>
                  }
                }
              />
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            className="about-img"
          >
            <img src={portadaSobreMi} alt="about" />
          </Col>
        </Row>

        <h1 className="project-heading">
          <FormattedMessage
                id="about.languages"
                defaultMessage="Languages"
                values={
                  {
                    inglés: <b className="green">Languages</b>,
                    español: <b className="green">Idiomas</b>
                  }
                }
              />
        </h1>

        <div>
          {languages.map((language, index) => (
            <LanguageProgressBar
              key={index}
              name={language.name}
              level={language.level}
              progress={language.progress}
            />
          ))}
        </div>

        <h1 className="project-heading" style={{marginTop: "10vh"}}>
          <FormattedMessage
                id="about.skillset"
                defaultMessage="Professional skillset"
                values={
                  {
                    inglés: <b className="green">skillset</b>,
                    español: <b className="green">Habilidades</b>
                  }
                }
              />
        </h1>

        <Techstack />

        <h1 className="project-heading" style={{marginTop: "5vh", marginBottom: "8vh"}}>
          <FormattedMessage
                id="about.tools"
                defaultMessage="Tools I use"
                values={
                  {
                    inglés: <b className="green">Tools</b>,
                    español: <b className="green">Herramientas</b>
                  }
                }
              />
        </h1>
        <Toolstack />
      </Container>
    </Container>
  );
}

export default About;
