import React, { useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import './Usuario.css';
import { useNavigate } from "react-router-dom";
import { auth } from '../../firebase/firebase';
import { confirmPasswordReset, updatePassword } from 'firebase/auth';

export default function RestablecerContraseña() {
  const [updateSuccessPassword, setUpdateSuccessPassword] = useState(false);
  const [updateErrorPassword, setUpdateErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    document.title = `User | PortfolioMGL`;
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate.push("/setting");
    }
  }, [currentUser, navigate]);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      // Lógica para restablecer la contraseña
    } catch {
      setError("Error al restablecer la contraseña");
    }

    setLoading(false);
  }

  async function handlePasswordChange(values) {
    const { password, confirmPassword } = values;
  
    if (password !== confirmPassword) {
      setUpdateSuccessPassword(false);
      setUpdateErrorPassword(true);
      return;
    }
  
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const oobCode = urlParams.get('oobCode');
  
      if (oobCode) {
        // Si hay un código de restablecimiento de contraseña en la URL, actualizar la contraseña del usuario
        await confirmPasswordReset(auth, oobCode, password);
      } else {
        // De lo contrario, simplemente actualizar la contraseña del usuario actual
        await updatePassword(auth.currentUser, password);
      }
      setUpdateErrorPassword(false);
      console.log('Contraseña actualizada.');
      setUpdateSuccessPassword(true);
      setTimeout(() => {
        navigate("/");
      }, 3000); // Redirigir al usuario después de 3 segundos (3000 milisegundos)
    } catch (error) {
      setUpdateSuccessPassword(false);
      console.log(error);
      setUpdateErrorPassword(true);
    }
  }
  


  return (
    <div className='contenedorRestablecerContraseña'>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.password) {
            errors.password = 'Por favor ingresa una contraseña';
          } else if (values.password.length < 6) {
            errors.password = 'La contraseña debe contener al menos 6 caracteres';
          }

          if (!values.confirmPassword) {
            errors.confirmPassword = 'Por favor confirma la contraseña';
          } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Las contraseñas no coinciden';
          }

          return errors;
        }}
        onSubmit={handlePasswordChange}
      >
        {({ errors, touched, handleChange }) => (
          <Form className='formulario'>
            <div>
              <label htmlFor='password'>Nueva contraseña</label>
              <Field
                type='password'
                id='password'
                name='password'
                placeholder='**********'
                onChange={handleChange}
              />
              {errors.password && touched.password && (
                <div className='error'>{errors.password}</div>
              )}

              <label htmlFor='confirmPassword'>Confirmar nueva contraseña</label>
              <Field
                type='password'
                id='confirmPassword'
                name='confirmPassword'
                placeholder='**********'
                onChange={handleChange}
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <div className='error'>{errors.confirmPassword}</div>
              )}

              <button type='submit'>Restablecer contraseña</button>

              {updateSuccessPassword && (
                <p className='exito'>¡Contraseña actualizada con éxito!</p>
              )}
              {updateErrorPassword && <p className="error">Tu contraseña no se ha actualizado. Inténtalo de nuevo tras cerrar sesión e iniciarla nuevamente.</p>}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
