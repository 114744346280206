//@typecheck

import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, setDoc, updateDoc, Timestamp } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail, updateEmail, updatePassword } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export async function registerNewUser(user) {
  try {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, user.uid), user);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function getUserInfo(uid) {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export async function userExists(uid) {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);

  return docSnap.exists();
}

export async function updateUser(user) {
  console.log(user);
  try {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, user.uid), user);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function logout() {
  await auth.signOut();
}

// Las siguientes funciones permiten resetear y/o actualizar contraseña y correo electónico de los usuarios:

export function FunctionResetPassword(email) {
  return sendPasswordResetEmail(email)
}

export function FunctionUpdateEmail(email) {
  return updateEmail(email)
}

export function FunctionUpdatePassword(password) {
  return updatePassword(password)
}


// La siguiente función modifica el displayName de los usuarios:


export async function updateDisplayName(uid, newDisplayName) {
  const usersRef = collection(db, "users");
  try {
    // Obtiene el documento del usuario antes de la actualización
    const userDocRef = doc(usersRef, uid);
    const userDocSnapshot = await getDoc(userDocRef);
    const userData = userDocSnapshot.data();

    // Crea un nuevo objeto de actualización con la nueva información
    const newUpdate = {
      displayName: newDisplayName,
      updatedAt: Timestamp.now(),
      updatedBy: uid,
    };

    // Crea un nuevo array de actualizaciones con el objeto de actualización
    const updatesArray = userData.updates || [];
    updatesArray.push(newUpdate);

    // Actualiza el documento con el nuevo array de actualizaciones
    await updateDoc(userDocRef, {
      displayName: newDisplayName,
      updates: updatesArray,
    });

    console.log("Se actualizó el displayName del usuario correctamente.");
  } catch (error) {
    console.error("Error al actualizar el displayName del usuario: ", error);
  }
}