import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          '"Esperanza de la Patria 2022"',
          "Título secundario de Bachiller en Economía y Administración",
          "Estudiante de la Carrera de Grado de Contador Público",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
