import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          '"Hope of the Homeland 2022"',
          "Secondary Bachelor's Degree in Economics and Administration",
          "Student of the Public Accountant Degree Career",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
