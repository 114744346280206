import { FormattedMessage } from "react-intl";

export const languages = [
    { name: <FormattedMessage id="about.lenguagesTitle1" defaultMessage="Spanish" />, 
    level: <FormattedMessage id="about.lenguagesType1" defaultMessage="Native" />, 
    progress: "end" },
    { name: <FormattedMessage id="about.lenguagesTitle2" defaultMessage="English" />, 
    level: <FormattedMessage id="about.lenguagesType2" defaultMessage="Intermediate" />, 
    progress: "center" },
    // { name: <FormattedMessage id="about.lenguagesTitle3" defaultMessage="Italian" />, 
    // level: <FormattedMessage id="about.lenguagesType3" defaultMessage="Basic: A2" />, 
    // progress: "start" }
  ];

export function LanguageProgressBar(props) {
    return (
      <div className="contentLanguageProgressBar">
        <h3 className="languageTitle">
          <strong>{props.name}</strong>
          </h3>
        <div className="languageProgressBar" style={{ textAlign: `${props.progress}` }}>
          <strong>{props.level}</strong>
        </div>
      </div>
    );
}
  