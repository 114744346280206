import { useEffect, useState } from "react";
import { onAuthStateChanged, updateEmail, updatePassword } from "firebase/auth";
import { auth, getUserInfo, updateDisplayName, updateUserName } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../funciones/Spinner";
import { Field, Form, Formik } from "formik";
import "./Usuario.css";
import { useIntl, FormattedMessage } from "react-intl";

export default function ConfiguracionUsuario() {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [updateSuccessEmail, setUpdateSuccessEmail] = useState(false);
  const [updateSuccessPassword, setUpdateSuccessPassword] = useState(false);
  const [updateSuccessName, setUpdateSuccessName] = useState(false);
  const [updateErrorEmail, setUpdateErrorEmail] = useState(false);
  const [updateErrorPassword, setUpdateErrorPassword] = useState(false);
  const [updateErrorName, setUpdateErrorName] = useState(false);
  const navigate = useNavigate();
  const intl = useIntl();

  const disposableEmails = ["yopmail.com", "otherexample.com"];

  useEffect(() => {
    document.title = `User | PortfolioMGL`;
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, callBackAuthState);
  }, []);

  async function callBackAuthState(user) {
    if (user) {
      const uid = user.uid;
      console.log(user);
      const loggedUser = await getUserInfo(uid);
      setCurrentUser(loggedUser);
      setIsLoading(false);
    } else {
      navigate("/");
    }
  }

  async function handleEmailChange(newEmail) {
    try {
      await updateEmail(auth.currentUser, newEmail);
      setUpdateErrorEmail(false);
      console.log("Correo electrónico actualizado.");
      setUpdateSuccessEmail(true);
    } catch (error) {
      setUpdateSuccessEmail(false);
      console.log(error);
      setUpdateErrorEmail(true);
    }
  }
  
  async function handlePasswordChange(newPassword) {
    try {
      await updatePassword(auth.currentUser, newPassword);
      setUpdateErrorPassword(false);
      console.log("Contraseña actualizada.");
      setUpdateSuccessPassword(true);
    } catch (error) {
      setUpdateSuccessPassword(false);
      console.log(error);
      setUpdateErrorPassword(true);
    }
  }
  


  async function handleDisplayNameUpdate() {
    try {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        await updateDisplayName(uid, newDisplayName);
        setUpdateErrorName(false);
        console.log("Se actualizó el displayName del usuario correctamente.");
        setUpdateSuccessName(true);
      }
    } catch (error) {
      setUpdateSuccessName(false);
      console.error("Error al actualizar el displayName del usuario: ", error);
      setUpdateErrorName(true);
    }
  }

  const wrongName1 = (
    <FormattedMessage
      id="user.wrongName1"
      defaultMessage="Please enter a name"
    />
  );

  const wrongName2 = (
    <FormattedMessage
      id="user.wrongName2"
      defaultMessage="The name can only contain letters and spaces"
    />
  );

  const wrongEmail1 = (
    <FormattedMessage
      id="user.wrongEmail1"
      defaultMessage="Please enter an e-mail"
    />
  );

  const wrongEmail2 = (
    <FormattedMessage
      id="user.wrongEmail2"
      defaultMessage="Invalid e-mail"
    />
  );

  const wrongPassword1 = (
    <FormattedMessage
      id="user.wrongPassword1"
      defaultMessage="Please enter a password"
    />
  );

  const wrongPassword2 = (
    <FormattedMessage
      id="user.wrongPassword2"
      defaultMessage="Password must contain at least 6 characters"
    />
  );
  
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="contenedorConfiguracionUsuario">
              <div className="contenedorConfiguracionLogins">
                <Formik
                  initialValues={{ nombre: "" }}
                  onSubmit={(values) => {
                    setNewDisplayName(values.nombre);
                    handleDisplayNameUpdate();
                  }}
                  validate={(values) => {
                    let errors = {};

                    // Validacion nombre
                    if (!values.nombre) {
                      errors.nombre = wrongName1;
                    } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.nombre)) {
                      errors.nombre = wrongName2;
                    }

                    return errors;
                  }}
                >
                  {({ errors, touched, handleChange, handleBlur, values }) => (
                    <Form className="formulario">
                      <div>
                        <label htmlFor="nombre">
                          <FormattedMessage
                            id="user.newName"
                            defaultMessage="New Full Name"
                          />
                        </label>
                        <input
                          type="text"
                          id="nombre"
                          name="nombre"
                          placeholder={intl.formatMessage({ id: 'user.placeholderName', defaultMessage: 'Example Name' })}
                          value={values.nombre}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setNewDisplayName(e.target.value);
                          }}
                        />
                        {touched.nombre && errors.nombre && (
                          <div className="error">{errors.nombre}</div>
                        )}
                        <button type="submit">
                          <FormattedMessage
                            id="user.updateName"
                            defaultMessage="Update Full Name"
                          />
                        </button>
                        {updateSuccessName && 
                        <p className="exito">
                          <FormattedMessage
                            id="user.updateSucessName"
                            defaultMessage="Full name updated successfully!"
                          />
                        </p>} 
                        {updateErrorName && 
                        <p className="error">
                          <FormattedMessage
                            id="user.updateWrongName"
                            defaultMessage="Your full name hasn't been updated. Try again."
                          />
                        </p>}
                      </div>
                    </Form>
                  )}
                </Formik>
                <Formik
                  initialValues={{ email: '' }}
                  validate={(values) => {
                    let errors = {};
                    if (!values.email) {
                      errors.email = wrongEmail1;
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = wrongEmail2;
                    } else {
                      const domain = values.email.split('@')[1];
                      if (disposableEmails.includes(domain)) {
                        errors.email = wrongEmail2;
                      }
                    }
                    return errors;
                  }}
                  onSubmit={(values) => handleEmailChange(values.email)}
                >           
                  {({ errors, touched, handleChange }) => (
                    <Form className="formulario">
                      <div>
                        <label htmlFor="email">
                          <FormattedMessage
                            id="user.newEmail"
                            defaultMessage="New E-mail"
                          />
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder={intl.formatMessage({ id: 'user.placeholderMail', defaultMessage: 'example@mail.com' })}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email && <div className="error">{errors.email}</div>}
                        <button type="submit">
                          <FormattedMessage
                            id="user.updateEmail"
                            defaultMessage="Update E-mail"
                          />
                        </button>
                        {updateSuccessEmail && 
                        <p className="exito">
                          <FormattedMessage
                            id="user.updateSucessEmail"
                            defaultMessage="E-mail updated successfully!"
                          />
                        </p>}
                        {updateErrorEmail && 
                        <p className="error">
                          <FormattedMessage
                            id="user.updateWrongEmail"
                            defaultMessage="Your e-mail hasn't been updated. Try again after logging out and logging back in."
                          />
                        </p>}
                      </div>
                    </Form>
                  )}
                </Formik> 
                <Formik
                  initialValues={{ password: '' }}
                  validate={(values) => {
                    let errors = {};
                    if (!values.password) {
                      errors.password = wrongPassword1;
                    } else if (values.password.length < 6) {
                      errors.password = wrongPassword2;
                    }
                    return errors;
                  }}
                  onSubmit={(values) => handlePasswordChange(values.password)}
                >           
                  {({ errors, touched, handleChange }) => (   
                    <Form className="formulario">
                      <div>
                        <label htmlFor="password">
                          <FormattedMessage
                            id="user.newPassword"
                            defaultMessage="New Password"
                          />
                        </label>
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder="**********"
                          onChange={handleChange}
                        />
                        {errors.password && touched.password && <div className="error">{errors.password}</div>}
                        <button type="submit">
                          <FormattedMessage
                            id="user.updatePassword"
                            defaultMessage="Update Password"
                          />
                        </button>
                        {updateSuccessPassword && 
                        <p className="exito">
                          <FormattedMessage
                            id="user.updateSucessPassword"
                            defaultMessage="Password updated successfully!"
                          />
                        </p>}
                        {updateErrorPassword && 
                        <p className="error">
                          <FormattedMessage
                            id="user.updateWrongPassword"
                            defaultMessage="Your password hasn't been updated. Try again after logging out and logging back in."
                          />
                        </p>}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div> 
      )}        
    </>     
  );        
}