import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../imagenes/PortadaHome.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import TypeEnglish from "./TypeEnglish";
import TypeSpanish from "./TypeSpanish";
import { FormattedMessage } from "react-intl";
import { langContext } from "../../contexto/langContext";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, getUserInfo, userExists } from "../../firebase/firebase";
import { Spinner } from "../../funciones/Spinner";

function Home() {
  const { establecerLenguaje } = useContext(langContext);
  const idioma = localStorage.getItem('lang');
  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    document.title = `Home | PortfolioMGL`;
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, callBackAuthState);
  }, []);

  async function callBackAuthState(user) {
    if (user) {
      const uid = user.uid;
      console.log(user);
      if (userExists(user.uid)) {
        const loggedUser = await getUserInfo(uid);
        setCurrentUser(loggedUser);
        if (loggedUser.displayName === "") {
          console.log("Regístrate.");
          navigate("/");
        } else {
          console.log("Ya estás registrado.");
          console.log(loggedUser);
        }
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
    setIsLoading(false);
  }

  // Verifica si el usuario está cargando o si el nombre aún no se ha establecido
  if (isLoading || !currentUser.displayName) {
    return <Spinner />;
  }

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                <FormattedMessage 
                  id="home.welcome"
                  defaultMessage="Hi,"
                />{currentUser.displayName}!
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
              <FormattedMessage 
                  id="home.presentation"
                  defaultMessage="I'm"
                />
                <strong className="main-name"> Marcos Gabriel Lorenzana</strong>
              </h1>

              <div className="typeStyle">
                <div>
                {idioma === 'es-AR' ? <TypeSpanish /> : <TypeEnglish />}
                </div>
              </div>
            </Col>

            <Col md={5} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
