import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import './Usuario.css';
import { Field, Formik, Form } from 'formik';

export default function ContraseñaOlvidada() {
  const [showError, setShowError] = useState(false);
  const [updateSuccessEmail, setUpdateSuccessEmail] = useState(false);
  const navigate = useNavigate();

  async function handleResetPassword(values) {
  if (!values.email) {
    setUpdateSuccessEmail(false);
    setShowError(true);
    return;
  }

  try {
    await sendPasswordResetEmail(auth, values.email);
    setShowError(false);
    setUpdateSuccessEmail(true);

    setTimeout(() => {
      navigate("/");
    }, 10000);

  } catch (error) {
    setUpdateSuccessEmail(false);
    console.error(error);
    setShowError(true);
  }
}


  return (
    <div className='contenedorContraseñaOlvidada'>
      <h1>Recuperar su contraseña</h1>
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.email) {
            errors.email = 'Por favor ingresa un correo electrónico';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Correo electrónico invalido';
          }
          return errors;
        }}
        onSubmit={handleResetPassword}
      >           
        {({ errors, touched, handleChange }) => (
          <Form className="formulario">
            <div>
              <label className='labelContraseñaOlvidada' htmlFor="email">Correo electrónico</label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="example@mail.com"
                onChange={handleChange}
              />
              {errors.email && touched.email && <div className="error">{errors.email}</div>}
              <button type="submit">Restablecer contraseña</button>
              {updateSuccessEmail && <p className="exito">Se ha enviado un correo electrónico para restablecer su contraseña. Revise su bandeja entrada o sus correos no deseados.</p>}
              {showError && <p className="error">El correo electrónico no se encuentra registrado u ocurrió otro error.</p>}
              <br />
              <p>
                <Link className='linkRegresar' to="/">
                  <span>← Regresar</span>
                </Link>
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
