import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { auth } from "../../firebase/firebase";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import "./Usuario.css";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";


export default function RegistroUsuario() {
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const intl = useIntl();

  useEffect(() => {
    document.title = `PortfolioMGL`;
  }, []);

  const [captchaValido, cambiarCaptchaValido] = useState(null);
	const [usuarioValido, cambiarUsuarioValido] = useState(false);

	const captcha = useRef(null);

  const onChange = () => {
		if(captcha.current.getValue()){
			console.log('El usuario no es un robot');
			cambiarCaptchaValido(true);
		}
	}

  const disposableEmails = ["yopmail.com", "otherexample.com"];

  const wrong1 = (
    <FormattedMessage
      id="user.wrong1"
      defaultMessage="E-mail currently registered and in use."
    />
  );

  const wrong2 = (
    <FormattedMessage
      id="user.wrong2"
      defaultMessage="Invalid e-mail. Use a valid one, please."
    />
  );

  const wrong3 = (
    <FormattedMessage
      id="user.wrong3"
      defaultMessage="Weak password. It should get better."
    />
  );

  const wrong4 = (
    <FormattedMessage
      id="user.wrong4"
      defaultMessage="Wrong password."
    />
  );

  const wrong5 = (
    <FormattedMessage
      id="user.wrong5"
      defaultMessage="User not found."
    />
  );

  const wrong6 = (
    <FormattedMessage
      id="user.wrong6"
      defaultMessage="Something happened wrong. Try it again later."
    />
  );

  const handleAuthEmail = async (values, { setSubmitting }) => {
    try {
      // check if email already exists
      const result = await fetchSignInMethodsForEmail(auth, values.email);
      if (result.length > 0) {
        // email already exists, sign in instead of creating a new account
        const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
        console.log(userCredential);
  
        // reset form and clear error message
        setEmail("");
        setPassword("");
        setErrorMessage("");
  
        // show welcome message
        showMessage(`Bienvenido devuelta, ${userCredential.user.email}`);

        // set formularioEnviado to true
        cambiarFormularioEnviado(true);
      } else {
        // email does not exist, create new account
        const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
        await updateProfile(userCredential.user, { displayName: values.nombre });
        console.log(userCredential);
  
        // reset form and clear error message
        setEmail("");
        setPassword("");
        setErrorMessage("");
  
        // show welcome message
        showMessage(`Bienvenido, ${userCredential.user.email}`);

        // set formularioEnviado to true
        cambiarFormularioEnviado(true);
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setErrorMessage(wrong1);
      } else if (error.code === "auth/invalid-email") {
        setErrorMessage(wrong2);
      } else if (error.code === "auth/weak-password") {
        setErrorMessage(wrong3);
      } else if (error.code === "auth/wrong-password") {
        setErrorMessage(wrong4);
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage(wrong5);
      } else {
        setErrorMessage(wrong6);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const showMessage = (message) => {
    console.log(message)
  };

  const wrongName1 = (
    <FormattedMessage
      id="user.wrongName1"
      defaultMessage="Please enter a name"
    />
  );

  const wrongName2 = (
    <FormattedMessage
      id="user.wrongName2"
      defaultMessage="The name can only contain letters and spaces"
    />
  );

  const wrongEmail1 = (
    <FormattedMessage
      id="user.wrongEmail1"
      defaultMessage="Please enter an e-mail"
    />
  );

  const wrongEmail2 = (
    <FormattedMessage
      id="user.wrongEmail2"
      defaultMessage="Invalid e-mail"
    />
  );

  const wrongPassword1 = (
    <FormattedMessage
      id="user.wrongPassword1"
      defaultMessage="Please enter a password"
    />
  );

  const wrongPassword2 = (
    <FormattedMessage
      id="user.wrongPassword2"
      defaultMessage="Password must contain at least 6 characters"
    />
  );

  return (
    <Formik
      initialValues={{
        nombre: '',
        email: '',
        password: ''
      }}
      validate={(values) => {
        let errors = {};

        // Validacion nombre
        if(!values.nombre){
          errors.nombre = wrongName1;
        } else if(!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.nombre)){
          errors.nombre = wrongName2;
        }

        if (!values.email) {
          errors.email = wrongEmail1;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = wrongEmail2;
        } else {
          const domain = values.email.split('@')[1];
          if (disposableEmails.includes(domain)) {
            errors.email = wrongEmail2;
          }
        }

        if (!values.password) {
          errors.password = wrongPassword1;
        } else if (values.password.length < 6) {
          errors.password = wrongPassword2;
        }

        if(captcha.current.getValue()){
          console.log('El usuario no es un robot');
          cambiarUsuarioValido(true);
          cambiarCaptchaValido(true);
        } else {
          console.log('Por favor acepta el captcha');
          cambiarUsuarioValido(false);
          cambiarCaptchaValido(false);
        }

        return errors;
      }}
      onSubmit={handleAuthEmail}
    >
      {({ errors, touched, handleChange, handleBlur, values }) => (
        <Form className="formulario">
          <div>
							<label htmlFor="nombre">
                <FormattedMessage
                  id="user.name"
                  defaultMessage="Full Name"
                />
              </label>
							<Field 
								type="text" 
								id="nombre" 
								name="nombre" 
								placeholder={intl.formatMessage({ id: 'user.placeholderName', defaultMessage: 'Example Name' })} 
								value={values.nombre}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							{touched.nombre && errors.nombre && <div className="error">{errors.nombre}</div>}
					</div>
          <div>
            <label htmlFor="email">
              <FormattedMessage
                id="user.email"
                defaultMessage="E-mail"
              />
            </label>
            <Field
              type="email"
              id="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'user.placeholderMail', defaultMessage: 'example@mail.com' })}
              onChange={handleChange}
            />
            {errors.email && touched.email && <div className="error">{errors.email}</div>}
          </div>
          <div>
            <label htmlFor="password">
              <FormattedMessage
                id="user.password"
                defaultMessage="Password"
              />
            </label>
            <Field
              type="password"
              id="password"
              name="password"
              placeholder="**********"
              onChange={handleChange}
            />
            {errors.password && touched.password && <div className="error">{errors.password}</div>}
            <div>
              <a href='https://passgenxer.gabyxer.com.ar' target="_blank" className="LinkGeneradorContraseña">
                <FormattedMessage
                  id="user.question1"
                  defaultMessage="Would you like to generate your password automatically?"
                />
              </a>
            </div>
            <div className="linkContraseñaOlvidada">
              <p>
                <FormattedMessage
                  id="user.question2"
                  defaultMessage="Did you forget your password?"
                /> 
                <Link to='/forgotten-password'>
                  <span>
                    <FormattedMessage
                      id="user.solution"
                      defaultMessage="Get it back"
                    />
                  </span>
                </Link>
              </p>
            </div>
          </div>
          <div className="contenedorRecaptcha">
						<ReCAPTCHA
							ref={captcha}
							sitekey="6LdbU34nAAAAAKNusdPZ1Wy4mawHsa34Xlv5BD7G"
							onChange={onChange}
						/>
            {captchaValido === false && 
            <div className="error">
              <FormattedMessage
                id="user.wrong7"
                defaultMessage="Please accept the captcha"
              />
            </div>}
					</div>
          <div className='contenedorBotonesInicio'>
            <button type="submit">
              <FormattedMessage
                id="user.login1"
                defaultMessage="Login"
              />
            </button>
          </div>
          {formularioEnviado && (
            <p className="exito">
              <FormattedMessage
                id="user.sucess"
                defaultMessage="Login successful!"
              />
            </p>
          )}
          {errorMessage && <p className="error">{errorMessage}</p>}
        </Form>
      )}
    </Formik>
	);
}